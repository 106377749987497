import {useEffect, useState} from 'react';
import {addLicenseContent, getLicensesList} from "./utils/licensing";
import {useDebounce} from "./useDebounce";  // Replace with the correct path

const useLicensingContent = ({platformType="platform", search="", page=1, size=10}) => {
    const [licenses, setLicenses] = useState([]);
    const [licensesLoading, setLicensesLoading] = useState(false);
    const [pagination, setPagination] = useState({
        totalItems:0,
        currentPage:1,
        totalPages:0,
        pageSize:0
    });

    const calculateUsage = (actualCount, totalCount) => {
        return actualCount ? Math.floor((parseInt(actualCount) / parseInt(totalCount))* 100) : 0
    }

    const handleLicenseListRetrieval = (data) => {
        setLicenses(Array.isArray(data?.results) ? data.results.filter((_license)=>!!_license?.active) : [])
        setPagination({
            totalItems:  data?.count,
            currentPage: page,
            totalPages: Math.ceil(data?.count / size),
            pageSize: size,
        });
        setLicensesLoading(false)
    }

    const handleCreateLicense = (data, callback) => {
        addLicenseContent(data, callback)
    }

    useDebounce(()=>{
        setLicensesLoading(true)
        getLicensesList(platformType, {
            /*length:size,*/
            page,
            ...(!!search ? {
                query:search
            } : {})
        }, handleLicenseListRetrieval, handleLicenseListRetrieval)
    }, 200, [search, page, size])

    return {
        pagination,
        licenses,
        licensesLoading,
        handleCreateLicense,
        calculateUsage
    }


};

export default useLicensingContent;
